import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "styled-bootstrap-grid";
import CardGameOld from "./../components/CardGameOld";
import { game } from "../data";
import styled from "styled-components";

export default function GameDtail({ t }) {
  let { slug } = useParams();
  const item = game.find((item) => item.slug === slug);

  if (item !== undefined) {
    return (
      <Container>
        {slug == "sport" ? (
          <iframe src="http://sport.db188.com/" width="100%" height="1000" style={{ border:"none" }}/>

        ) : (
          <DetailWrap>
            <CardGameOld data={item} button />
          </DetailWrap>
        )}
      </Container>
    );
  } else {
    return null;
  }
}

const DetailWrap = styled.div`
  .card-game {
    aspect-ratio: 20/5;
    background-size: 25%;
    border-radius: 0;
  }
`;
