import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../theam";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import logo from "./../images/logo.gif";
import en from "./../images/en.svg";
import km from "./../images/km.svg";
import { device, size } from "./style/breakpoints";
import { setLanguage } from "react-switch-lang";
import { IconButton, Snackbar } from "@material-ui/core";
import config, { domain } from "./../config";
import { getCookie } from "./../utils";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { openModal } from "./../features/signUp";
import { Input, Button } from "./Component";
import { useMediaQuery } from "react-responsive";
import MobileHeader from "./MobileHeader";
import {
  Home,
  Lotto,
  Keno,
  Sport,
  Casino,
  Cock,
  Promotion,
  K99,
  Joker,
} from "./icons";

export default function MainHeader({ t }) {
  const [action, setAction] = useState({
    loginerror: false,
    type: "",
    msg: "",
  });
  const [open, setOpen] = React.useState(false);
  const node = React.useRef();
  const handleSetLanguage = (key) => () => {
    setLanguage(key);
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  // login

  const login = (e) => {
    e.preventDefault();
    const loginData = {
      username: username,
      password: password,
      cert: config.cert,
    };
    if (username === "") {
      setAction({ loginerror: true, msg: "Your username must be empty!" });
      return;
    } else if (password === "") {
      setAction({ loginerror: true, msg: "Your Password must be empty!" });
      return;
    }
    fetch(`${config.baseURL}/api/cash/login/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: config.token,
      },
      body: JSON.stringify(loginData),
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res.required_change) {
          await fetch(`${config.baseURL}/api/user-auth/user_change_pwd/`, {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `sid ${res.sessionid}`,
            },
            body: JSON.stringify({
              your_password: "qawsedrf",
              password: loginData.password,
            }),
          })
            .then((response) => response.json())
            .then((response) => {
              const en = getCookie("lang");
              const re = `${res.domain}/?sid=${res.sessionid}&uid=${res.userid}&cert=${config.cert}&language=${en}`;
              window.location.href = re;
            });
        } else if (res.status === "success") {
          const en = getCookie("lang");
          const re = `${res.domain}/?sid=${res.sessionid}&uid=${res.userid}&cert=${config.cert}&language=${en}`;
          window.location.href = re;
        } else {
          setAction({ loginerror: true, msg: res.message });
        }
      });
  };

  const { loginerror, msg } = action;
  const isTabletOrMobileDevice = useMediaQuery({ maxWidth: 763 });

  return (
    <Header>
      {isTabletOrMobileDevice ? (
        <MobileHeader t={t} />
      ) : (
        <>
          <Container>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={loginerror}
              autoHideDuration={2000}
              onClose={() => setAction({ loginerror: false })}
            >
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {msg} — <strong>check it out!</strong>
              </Alert>
            </Snackbar>
            <Row style={{ justifyContent: "space-between", marginTop: "20px" }}>
              <Col xs={6} md={2}>
                <Logo
                  to="/"
                  style={{
                    width: 250,
                    height: 70,
                    backgroundPosition: "center",
                    backgroundSize: "130%",
                    backgroundRepeat: "no-repeat",
                    backgroundImage: `url(${logo})`,
                    display: "block",
                  }}
                ></Logo>
              </Col>
              <Col xs={6} md={10}>
                <FrmWrap>
                  <div
                    style={{ display: "flex", marginRight: 10, padding: 10 }}
                  >
                    <IconButton onClick={handleSetLanguage("km")}>
                      <Lang
                        style={{ backgroundImage: `url(${km})` }}
                      ></Lang>
                    </IconButton>
                    <IconButton onClick={handleSetLanguage("en")}>
                      <Lang
                        style={{ backgroundImage: `url(${en})` }}
                      ></Lang>
                    </IconButton>
                  </div>
                  <form method="POST">
                    <Input
                      text="text"
                      autocomplete="false"
                      onChange={(e) =>
                        setUsername(e.target.value.toUpperCase())
                      }
                      value={username}
                      placeholder={t("user.username")}
                    />

                    <Input
                      autocomplete="false"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder={t("user.password")}
                    />
                    <Button type="submit" onClick={(e) => login(e)}>
                      {t("user.login")}
                    </Button>
                    {window.location.hostname === domain ? (
                      ""
                    ) : (
                      <Button
                        type="reset"
                        onClick={() => dispatch(openModal())}
                      >
                        {t("user.signup")}
                      </Button>
                    )}
                  </form>
                </FrmWrap>
              </Col>
            </Row>
          </Container>
          <Container fluid style={{ backgroundColor: colors.primary }}>
            <Container>
              <Row>
                <Col ref={node}>
                  <Burger open={open} setOpen={setOpen} />
                  <Menu t={t} open={open} setOpen={setOpen} />
                </Col>
              </Row>
            </Container>
          </Container>
        </>
      )}
    </Header>
  );
}

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const Menu = ({ open, t, setOpen }) => {
  const menu = [
    {
      id: 1,
      icon: <Sport />,
      slug: "sport",
      name: t("menu.sport"),
    },
    {
      id: 2,
      icon: <Keno />,
      slug: "keno",
      name: t("menu.keno"),
    },
    {
      id: 3,
      icon: <Lotto />,
      slug: "lotto",
      name: t("menu.lotto"),
    },
    {
      id: 5,
      icon: <K99 />,
      slug: "k99",
      name: t("menu.k99"),
    },
    {
      id: 7,
      icon: <Casino />,
      slug: "casino",
      name: t("menu.casino"),
    },
    {
      id: 8,
      icon: <Cock />,
      slug: "cockfighter",
      name: t("menu.cockfighter"),
    },
    {
      id: 9,
      icon: <Joker />,
      slug: "joker",
      name: t("menu.joker"),
    },
  ];
  return (
    <Ul open={open} onClick={() => setOpen(!open)}>
      <NavLink exact activeClassName="active" to="/"  style={{ width: "100%" }}>
        <Li>
          <span className="icon">
            <Home color={colors.white} />
          </span>
          <span>{t("menu.home")}</span>
        </Li>
      </NavLink>
      {menu.map((item, idx) => (
        <NavLink activeClassName="active" key={idx} to={`/game/${item.slug}`} style={{ width: "100%" }}>
          <Li>
            <span className="icon">{item.icon}</span>
            <span>{item.name}</span>
          </Li>
          <SubGame className="overmenu" item={item} />
        </NavLink>
      ))}
      <NavLink
        activeClassName="active"
        to="/promotion"
        style={{ width: "100%" }}
      >
        <Li>
          <span className="icon">
            <Promotion />
          </span>
          <span>{t("menu.promotion")}</span>
        </Li>
      </NavLink>
    </Ul>
  );
};

const SubGame = ({ item }) => {
  const sitem = item.subgame;
  if (sitem) {
    return (
      <SubGameWrap>
        {sitem.map((item, idx) => (
          <Game>{item.name}</Game>
        ))}
      </SubGameWrap>
    );
  } else {
    return "";
  }
};
const Game = styled.div`
  background-color: ${colors.primary};
`;

const SubGameWrap = styled.div`
  display: none;
  transition: all ease-in 0.3s;
  position: absolute;
  left: 0;
  z-index: 4;
  width: 100%;
`;
const Lang = styled.div`
  border: 5px;
  color: ${colors.white};
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

const FrmWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  form {
    display: flex;
    padding: 12px 0;
    gap: 10px;
    flex-direction: row;
  }
`;
const StyledBurger = styled.button`
  @media screen and (min-width: ${size.sm}) {
    display: none;
  }
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background: ${({ open }) => (open ? colors.white : colors.primary)};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Header = styled.header`
  background: ${colors.white};
`;
const Logo = styled(Link)`
  display: block;
  padding: 0;
  height: 100px;
`;
const Ul = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  .active {
    background: ${colors.primary300};
  }
`;
const Li = styled.li`
  list-style: none;
  padding: 15px 20px;
  margin: 0;
  width: 100%;
  text-transform: uppercase;
  font-weight: 600;
  color: ${colors.white};
  transition: 0.3s;
  display: flex;
  justify-content: center;
  gap: 3px;
  &:hover {
    background: ${colors.primary300};
  }
  .icon {
    width: 24px;
    height: 24px;
  }
  svg {
    width: 24px;
    height: 24px;
  }
  span {
    font-size: 16px;
    line-height: 27px;
  }
`;
