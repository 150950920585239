import React from 'react';
import { Link } from 'react-router-dom';
import { Container} from 'styled-bootstrap-grid'
import styled from 'styled-components'
import {colors} from './../theam'
import { Notfound as NotFoundIcon} from './../components/icons'
const NotFound = () => (
    <Container>
        <Not>
            <NotFoundIcon/>
            <h1>404 - Not Found!</h1>
            <Link to="/">
                Go Home
            </Link>
        </Not>
        
    </Container>
);

export default NotFound;


const Not = styled.div`
    text-align: center;
    height: 50vh;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    h1{
        margin-top: 30px;
        color: ${colors.primary};
        font-weight: 700;
        font-size: 50px;
    }
    a{
        display: inline;
        background-color: ${colors.primary};
        margin-top: 50px;
        padding: 15px 30px;
        border-radius: 10px;
        color: ${colors.white};
        text-transform: uppercase;
        font-weight: 600;
        transition:  all ease-in-out 0.3s;
        &:hover{
            padding: 15px 35px;
            background-color: ${colors.secondary};
        }
    }
`;