
export const domain="2888db.com";

const dev = {
    baseURL: 'http://api-dev.2888new.com',
    cert: '8aoFZHXXV2LP3cMfPrFAod',
    token: 'Token 65139eab1d9c30b9459668713ad6ab67034e16d1',
    pageId: "101995718336038",
    appId: "572627453444058",
    apiUrl: "http://127.0.0.1:8000/api",
    mediaUrl: "http://127.0.0.1:8000/",
}

const staging = {
    baseURL: 'http://api-dev.2888new.com',
    cert: '8aoFZHXXV2LP3cMfPrFAod',
    token: 'Token 65139eab1d9c30b9459668713ad6ab67034e16d1',
    pageId: "101995718336038",
    appId: "572627453444058",
    apiUrl: "http://db88.onebeta.club/api",
    mediaUrl: "http://db88.onebeta.club/",
    certpro:"5rqHYG6ZYYfGK5q5vzQNcd"
}
const certdb188 = '5rqHYG6ZYYfGK5q5vzQNcd'
const cert2888db = 'C8i7W4TwWZyH2ZpFFdnpWc'


const pro = {
    baseURL: 'http://p-api.sbc369.club',
    cert: window.location.hostname === domain ? cert2888db : certdb188,
    token: 'Token 58dc1e47e54035235c28daef0b17e2dd1951e1a0',
    pageId: "101995718336038",
    appId: "572627453444058",
    apiUrl: "http://db188.onebeta.club/api/",
    mediaUrl: "http://db188.onebeta.club/",
}

export default pro


