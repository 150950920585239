import { createSlice } from '@reduxjs/toolkit'

export const popUp = createSlice({
    name: 'popup',
    initialState: {
        modal1:false,
        data:{},
    },
    reducers: {
        openModal1: (state) => {
            state.modal1 = true
        },
        closeModal:(state) =>{
            state.modal1 = false
        },
        setModalData:(state,action)=>{
            state.data = action.payload
        }
    },
})
export const { openModal1,closeModal,setModalData} = popUp.actions

export default popUp.reducer