import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { colors } from "../theam";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImage } from "../utils";
import moment from 'moment';
import footballfield from "../images/footballfield.png"
export default function SlideVertical({ data }) {
  const option = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: true,
    dots: true,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    slidesToShow: data?.length<4?data?.length:4,
    slidesToScroll: 4
  };
  return (
    <ContainerMatch>
      <Slider {...option}>
        {
          data?.map((item, key) => {
            return (
              <BoxMatch key={key}>
                <MatchCard>
                  <TeamOne>
                    <img src={getImage(item.logo_1)} alt="sport" />
                    <span>{item.name_1}</span>
                  </TeamOne>
                  <MatchDetail>
                    <h5>{item.league}</h5>
                    <p className="date">{moment(item.date).format('Do MMMM YYYY, h:mm a')}</p>
                  </MatchDetail>
                  <TeamTwo>
                    <img src={getImage(item.logo_2)} alt="football" />
                    <span>{item.name_2}</span>
                  </TeamTwo>
                </MatchCard>
              </BoxMatch>
            );
          })}
      </Slider>
    </ContainerMatch>
  );
}
const ContainerMatch = styled.div`
  width: 100%;
`;
const BoxMatch = styled.div`
  width: 100%;
  height: 93px;
  background-size: 112% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${footballfield});
  padding: 5px 5px 0px 5px;
  box-sizing: border-box;
  margin-bottom: 3px;
`;
const MatchCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  width: 100%;
  h5 {
    color: #fee000;
  }
`;
const TeamOne = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 25%;
  height: 80px;
  span {
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    color: ${colors.white};
    text-align: center;
    font-weight: 500;
  }
  img {
    width: 100%;
    height: 60%;
    border-radius: 100px;
    object-fit: contain;
  }
`;
const TeamTwo = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 25%;
  height: 80px;
  span {
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    color: ${colors.white};
    text-align: center;
    font-weight: 500;
  }
  img {
    width: 100%;
    height: 60%;
    border-radius: 100px;
    object-fit: contain;
  }
`;
const MatchDetail = styled.div`
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  p {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    letter-spacing: 0.5px;
  }
`;
