import React from "react";
import styled from "styled-components";
import { colors } from "../theam";
import { cutSting, getImage } from "../utils";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
export default function CardGame({ data }) {
  if (data == null) {
    return "";
  }
  return (
    <News>
      <OwlCarousel
        options={{
          items: 4,
          nav: true,
          loop: true,
          autoplay: true,
          autoplayTimeout: 5000,
          autoplayHoverPause: true,
          margin: 10,
          stagePadding: 50,
          navText: [
            `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="39.547" viewBox="0 0 24 39.547">
              <path id="Path_53" data-name="Path 53" d="M28.131,58.968l17.6,17.6a2.173,2.173,0,0,0,3.074,0l2.053-2.053a2.173,2.173,0,0,0,0-3.07L36.913,57.431,50.861,43.417a2.173,2.173,0,0,0,0-3.07l-2.053-2.053a2.173,2.173,0,0,0-3.074,0l-17.6,17.6A2.174,2.174,0,0,0,28.131,58.968Z" transform="translate(-27.494 -37.657)" fill=${colors.white} />
          </svg>`,
            `<svg svg xmlns = "http://www.w3.org/2000/svg" width = "24" height = "39.547" viewBox = "0 0 24 39.547" >
          <path id="Path_53" data-name="Path 53" d="M50.858,58.968l-17.6,17.6a2.173,2.173,0,0,1-3.074,0l-2.053-2.053a2.173,2.173,0,0,1,0-3.07L42.076,57.431,28.127,43.417a2.173,2.173,0,0,1,0-3.07l2.053-2.053a2.173,2.173,0,0,1,3.074,0l17.6,17.6A2.173,2.173,0,0,1,50.858,58.968Z" transform="translate(-27.494 -37.657)" fill=${colors.white} />
          </svg>`,
          ],
          responsive: {
            0: {
              items: 2,
              stagePadding: 0,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 3,
            },
          },
        }}
      >
        {data &&
          data.map((item, idx) => (
            <Link key={idx} to={`/article/${item.id}`}>
              <NewsWrap>
                <div
                  className="card-game"
                  style={{ backgroundImage: `url(${getImage(item.image)})` }}
                ></div>
                <span>
                  <h1>{cutSting(item.title, 30)}</h1>
                  <p>{cutSting(item.description, 40)}</p>
                </span>
              </NewsWrap>
            </Link>
          ))}
      </OwlCarousel>
    </News>
  );
}
const News = styled.div`
  overflow: hidden;
  &:hover {
    .owl-nav {
      opacity: 1;
    }
  }
  .owl-nav {
    opacity: 0;
    transition: all ease-in-out 0.3s;
    svg {
      width: 13px;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .owl-prev {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(10%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: saturate(180%) blur(20px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .owl-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-10%, -50%);
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: saturate(180%) blur(20px);
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

const NewsWrap = styled.div`
  .card-game {
    background-color: ${colors.primary100};
    border-radius: 5px;
    aspect-ratio: 16 / 8;
    background-position: center;
    background-repeat: no - repeat;
    background-size: cover;
    transition: all ease -in -out 0.3s;
    background-clip: padding - box;
    overflow: hidden;
  }
  span {
    padding: 10px;
    display: block;
  }
  div {
    padding: 30px;
  }
  h1 {
    color: ${colors.primary};
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin: 0;
    text-transform: capitalize;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    color: ${colors.primary};
  }
`;
