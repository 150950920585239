import React from "react";
import styled from "styled-components";
import { colors } from "../theam";
import { Button } from "./Component";
export default function CardGameOld({ button, data }) {
  const { name, img, des } = data;
  return (
    <GameWrap className="card-game" style={{ backgroundImage: `url(${img})` }}>
      <div>
        <h1>{name}</h1>
        <p>{des}</p>
      </div>
      {button ? <Button className="button">play</Button> : ""}
    </GameWrap>
  );
}
const GameWrap = styled.div`
  background-color: ${colors.primary100};
  border-radius: 5px;
  aspect-ratio: 16/8;
  background-position: 90% 50%;
  background-repeat: no-repeat;
  background-size: 47%;
  transition: all ease-in-out 0.3s;
  background-clip: padding-box;
  position: relative;
  overflow: hidden;
  @keyframes shine {
    0% {
      top: -120%;
      left: -120%;
    }
    20% {
      left: 100%;
      top: 100%;
    }
    40% {
      left: 100%;
      top: 100%;
    }
    100% {
      left: 100%;
      top: 100%;
    }
  }
  &:hover {
    background-color: ${colors.primary200};
    &:before {
      content: "";
      z-index: 3;
      position: absolute;
      height: 100%;
      width: 100%;
      top: -120%;
      left: -120%;
      background: linear-gradient(
        transparent 0%,
        rgba(255, 255, 255, 0.1) 45%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0.1) 55%,
        transparent 100%
      );
      transition: all 2s;
      transform: rotate(-45deg);
      animation: shine 7s infinite forwards;
    }
  }
  div {
    padding: 30px;
  }
  h1 {
    color: ${colors.primary};
    line-height: 30px;
    font-size: 25px;
    max-width: 50%;
    margin: 0;
    text-transform: capitalize;
  }
  p {
    margin-top: 15px;
    margin-bottom: 0;
    max-width: 50%;
    font-weight: 500;
    line-height: 23px;
    color: ${colors.primary};
  }
  .button {
    position: absolute;
    display: inline-block;
    width: 120px;
    padding: 15px;
    left: 30px;
    bottom: 30px;
  }
`;
