import React from "react";
import styled from "styled-components";
import OwlCarousel from "react-owl-carousel2";
import { colors } from "../theam";
import { getImage } from "../utils";
const option = {
  items: 1,
  nav: true,
  loop: true,
  rewind: true,
  autoplay: true,
  autoplayTimeout: 5000,
  autoplayHoverPause: true,
  margin: 0,
};

export default function Slide({ data }) {
  if (data == null) {
    return "";
  }
  return (
    <Wrapslide>
      <OwlCarousel options={option}>
        {data &&
          data.map((index, key) => {
            return (
              <div className="image-slide" key={key}>
                <img src={getImage(index.image)} />
              </div>
            );
          })}
      </OwlCarousel>
    </Wrapslide>
  );
}

const Wrapslide = styled.div`
  .image-slide {
    width: 100%;
    height: auto;
    background-color: ${colors.primary200};
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
    /* @media screen and (min-width: 1399.98px) {
      height: 365px;
    } */
    /* @media (min-width: 992px) and (max-width: 1399.96px) {
      height: 320px;
    }
    @media (max-width: 575.98px) {
      height: 320px;
    } */
  }
  width: 100%;
  position: relative;
  .owl-nav {
    display: none;
  }
  .owl-dots {
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
  }

  .owl-dot {
    width: 20px;
    height: 10px;
    margin: 0 6px;
    outline: none;
    transition: 0.3s linear;
    background-color: ${colors.secondary};
  }

  .owl-dot.active {
    width: 40px;
    height: 10px;
    border: 3px solid ${colors.secondary};
    background: none !important;
  }

  .owl-dot:hover {
    width: 40px;
    border: 3px solid ${colors.secondary};
    background: ${colors.secondary};
    background: none !important;
  }
`;
