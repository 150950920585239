import React, { Component } from "react";
import MainHeader from "./components/Header";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { colors } from "./theam";
import axios from "axios";
// Router
import Home from "./router/Home";
import Gamedtail from "./router/GameDtail";
import Article from "./router/Article";
import Promotion from "./router/Promotion";
import NotFound from "./router/NotFound";
import Signup from "./components/Signup";
import Login from "./components/Login";
import BottomNav from "./components/BottomNav";

import { Link } from "react-router-dom";
import styled from "styled-components";
//icon
import { Call, CTelegram, IFaceBook } from "./components/icons";

import Footer from "./Footer";
import {
  setTranslations,
  setLanguageCookie,
  translate,
  setDefaultLanguage,
} from "react-switch-lang";

import en from "./lang/en.json";
import km from "./lang/km.json";
import MessengerCustomerChat from "react-messenger-customer-chat";
import config, { domain } from "./config";

setTranslations({ en, km });
setDefaultLanguage("km");
setLanguageCookie("lang");

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      global: {},
    };
  }
  componentDidMount() {
    axios.get("/home").then((response) => {
      this.setState({ global: response.data });
    });
  }
  render() {
    const { t, location } = this.props;
    const { footer } = this.state.global;
    const url = window.location.href;
    const part = url.substring(url.lastIndexOf("/") + 1);
    if (part === "promotioniframe") {
      return (
        <Router>
          <Route
            path="/promotioniframe"
            component={() => <Promotion t={t} />}
          />
        </Router>
      );
    }

    return (
      <Router>
        {window.location.hostname === domain ? (
          ""
        ) : (
          <MaskRight>
            <MaskBackgroundRight className="mbg">
              <img
                className="menuIcon_img"
                src="https://www.cmd368.com/css/common/icon/icon.live.png"
              />
              <p>{t("label.contact")}</p>
            </MaskBackgroundRight>
            <ContactUsBar className="cub">
              <BarContainer>
                <Social>
                  <Link
                    to=""
                    onClick={() =>
                      window.open(
                        footer && footer["facebook"]["content"],
                        "_blank"
                      )
                    }
                  >
                    <SIcon>
                      <IFaceBook />
                    </SIcon>
                    <span>
                      <strong>Facebook</strong>
                      Click Here
                    </span>
                  </Link>
                </Social>
                <Social>
                  <Link to="">
                    <SIcon>
                      <Call />
                    </SIcon>
                    <span>
                      <strong>Hot Line</strong>
                      {footer && footer["smart"]["content"]}
                    </span>
                  </Link>
                </Social>
                <Social>
                  <Link
                    to=""
                    onClick={() =>
                      window.open(
                        footer && footer["telegram"]["content"],
                        "_blank"
                      )
                    }
                  >
                    <SIcon>
                      <CTelegram />
                    </SIcon>
                    <span>
                      <strong>Telegram</strong>
                      {footer && footer["cellcard"]["content"]}
                    </span>
                  </Link>
                </Social>
              </BarContainer>
            </ContactUsBar>
          </MaskRight>
        )}
        <div className="app" style={{ background: colors.gray }}>
          <MainHeader t={t} />
          <Switch>
            <Route
              path="/"
              exact
              component={() => <Home t={t} getdata={this.state.global} />}
            />
            <Route path="/f/:fid" component={() => <Home t={t} />} />
            <Route path="/game/:slug" component={() => <Gamedtail t={t} />} />
            <Route path="/promotion" component={() => <Promotion />} />
            <Route path="/article/:id" component={() => <Article t={t} />} />
            <Route component={NotFound} />
          </Switch>
          {window.location.hostname === domain ? (
            ""
          ) : (
            <Footer t={t} getdata={this.state.global.footer} />
          )}
          <Signup t={t} />
          <Login t={t} />
          <BottomNav />
          <MessengerCustomerChat pageId={config.pageId} appId={config.appId} />
        </div>
      </Router>
    );
  }
}

export default translate(App);

const MaskRight = styled.div`
  position: fixed;
  top: 174px;
  right: 0;
  width: 40px;
  z-index: 99999;
  &:hover .mbg {
    right: -60px;
  }
  &:hover .cub {
    right: -186px;
  }
`;

const MaskBackgroundRight = styled.div`
  text-align: center;
  position: relative;
  width: 40px;
  height: 300px;
  right: 0;
  z-index: 24;
  background: -moz-linear-gradient(
    top,
    rgba(11, 103, 172, 0.75) 0%,
    rgba(6, 56, 93, 0.75) 52%,
    rgba(1, 12, 20, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(11, 103, 172, 0.75) 0%,
    rgba(6, 56, 93, 0.75) 52%,
    rgba(1, 12, 20, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(11, 103, 172, 0.75) 0%,
    rgba(11, 103, 172, 0.75) 52%,
    rgba(1, 12, 20, 0) 100%
  );
  transition: all 0.5s;
  .menuIcon_img {
    margin-top: 10px;
    width: 30px;
  }
  p {
    width: 140px;
    text-align: center;
    color: #fff;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    position: absolute;
    top: 128px;
    left: -50px;
    letter-spacing: 4px;
    padding: 5px;
    cursor: pointer;
    font-size: 1rem;
    white-space: nowrap;
  }
`;

const ContactUsBar = styled.div`
  position: fixed;
  top: 174px;
  right: -240px;
  z-index: 23;
  transition: all 0.5s;
  z-index: 999999;
`;
const BarContainer = styled.div`
  width: 240px;
`;
const Social = styled.div`
  position: relative;
  right: 0px;
  background: rgba(11, 103, 172, 0.6);
  border-top-left-radius: 5px;
  transition: all 0.5s;
  &::after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: "";
    width: 100%;
    height: 1px;
    background: #000204;
    background: -moz-linear-gradient(
      left,
      #000204 0%,
      white 50%,
      #000204 100%,
      #7db9e8 100%
    );
    background: -webkit-linear-gradient(
      left,
      #000204 0%,
      white 50%,
      #000204 100%,
      #7db9e8 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#000204),
      color-stop(50%, white),
      color-stop(100%, #000204),
      to(#7db9e8)
    );
    background: -o-linear-gradient(
      left,
      #000204 0%,
      white 50%,
      #000204 100%,
      #7db9e8 100%
    );
    background: linear-gradient(
      to right,
      #000204 0%,
      white 50%,
      #000204 100%,
      #7db9e8 100%
    );
    a {
      display: block;
      color: #fff;
      padding: 14px;
      word-wrap: break-word;
      cursor: pointer;
      background: #0b67ac;
      background: -moz-linear-gradient(
        top,
        rgba(11, 103, 172, 0.75) 0%,
        rgba(1, 12, 20, 0.9) 100%
      );
      background: -webkit-linear-gradient(
        top,
        rgba(11, 103, 172, 0.75) 0%,
        rgba(1, 12, 20, 0.9) 100%
      );
      background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(11, 103, 172, 0.75)),
        to(rgba(1, 12, 20, 0.9))
      );
      background: -o-linear-gradient(
        top,
        rgba(11, 103, 172, 0.75) 0%,
        rgba(1, 12, 20, 0.9) 100%
      );
      background: linear-gradient(
        to bottom,
        rgba(11, 103, 172, 0.3) 0%,
        rgba(1, 12, 20, 0.3) 100%
      );
      font-size: 0.875rem;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
      transition: all 0.3s;
      &::before {
        content: "";
        display: inline-block;
        height: 100%;
        width: 0;
        vertical-align: middle;
      }
    }
  }
  &:hover {
    right: 150px;
    background: rgba(11, 103, 172, 0.85);
  }
  a {
    span {
      display: inline-block;
      vertical-align: middle;
      text-transform: none;
      margin-left: 7px;
      strong {
        display: block;
        text-transform: uppercase;
      }
    }
  }
`;

const SIcon = styled.span`
  width: 20px;
  margin: 0 20px 0 10px;
  display: inline-block;
  vertical-align: middle;
  padding: 10px 10px 10px 7px;
`;
