import chicken from './images/gameimg/cock.png'
import keno from './images/gameimg/keno.png'
import live_casino from './images/gameimg/casino.png'
import slot from './images/gameimg/slot.png'
import sport from './images/gameimg/sport.png'
import joker from './images/gameimg/joker.png'


import aba from './images/bank/aba.png'
import ac from './images/bank/ac.png'
import pipay from './images/bank/pipay.png'
import truemoney from './images/bank/truemoney.png'
import wing from './images/bank/wing.png'
const bank = [
    {
        id: 1,
        name: 'aba',
        userName: 'Sony',
        accNum: '002 167 931',
        img: aba,

    },
    {
        id: 1,
        name: 'ac',
        userName: 'So ny',
        accNum: '06000385066416',
        img: ac,

    },
    {
        id: 1,
        name: 'pipay',
        userName: 'Sony',
        accNum: '0976279229',
        img: pipay,

    },
    {
        id: 1,
        name: 'truemoney',
        userName: 'Chhum narin',
        accNum: '0976279229',
        img: truemoney,

    },
    {
        id: 1,
        name: 'wing',
        userName: 'Chhum narin',
        accNum: '03768291',
        img: wing,

    }
]


const game = [
    {
        id: 1,
        slug: 'cockfighter',
        name: 'ប្រជល់មាន់',
        img: { chicken }.chicken,
        des:"គូរខ្លាំងៗជក់ចិត្ត"
    },
    {
        id: 2,
        slug: 'keno',
        name: 'គីណូ',
        img: { keno }.keno,
        des: "វេទិកាជាច្រើនសម្រាប់អ្នកជ្រើសរើស"
    },
    {
        id: 3,
        slug: 'casino',
        name: 'កាស៊ីណូផ្សាយផ្ទាល់',
        img: { live_casino }.live_casino,
        des: "ហ្កេមកាសុីណូ Online ដ៍ជឿទុកចិត្ត"
    },
    {
        id: 4,
        slug: 'slot',
        name: 'ស្លុត',
        img: { slot }.slot,
        des: "ហ្កេមកាសុីណូ Online ដ៍ជឿទុកចិត្ត"
    },
    {
        id: 5,
        slug: 'sport',
        name: 'កីឡា',
        img: { sport }.sport,
        des: "ការប្រកួតពិសេសៗ​រាប់ពាន់គូរ"
    },
    {
        id: 5,
        slug: 'joker',
        name: 'ជូកឺ',
        img: { joker }.joker,
        des: "ការប្រកួតពិសេសៗ​រាប់ពាន់គូរ"
    },
];
export { game, bank };