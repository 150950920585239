import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";

import styled from "styled-components";
import { colors } from "./theam";

import logo from "../src/images/logo.gif";
import SBC369 from "../src/images/sbc369.svg";
import Joker from "../src/images/joker.svg";
import k99 from "../src/images/k99.svg";
import AE from "../src/images/AE.svg";
import ag from "../src/images/ag.png";
import gameworld from "../src/images/gameworld.png";
import sv from "../src/images/sv.png";

import { Link } from "react-router-dom";
import { Phone } from "@material-ui/icons";
import { bank } from "./data";
import { FaceBook, Telegram, Line } from "./components/icons";
import { Label } from "./components/Component";
import { useMediaQuery } from "react-responsive";

export default function Footer({ t, getdata }) {
  const isTabletOrMobileDevice = useMediaQuery({ maxWidth: 763 });

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={4}>
            <Label>{t("label.payment")}</Label>
            <BankW>
              {bank.map((item, idx) => {
                return (
                  <BankCard key={idx}>
                    <img src={item.img} alt={item.name} />
                  </BankCard>
                );
              })}
            </BankW>
          </Col>
          <Col md={4}>
            <Label>{t("label.contact")}</Label>
            <Wphone>
              <Phone
                className="icon"
                fontSize={"large"}
                style={{ color: colors.primary }}
              />
              <span>
                <p>{getdata ? getdata["smart"]["content"] : ""}</p>
                <p>{getdata ? getdata["cellcard"]["content"] : ""}</p>
                <p>{getdata ? getdata["metfone"]["content"] : ""}</p>
              </span>
            </Wphone>
          </Col>

          <Col md={4}>
            <Label>{t("label.socail")}</Label>
            <BankW>
              <Socile
                onClick={() =>
                  window.open(
                    getdata && getdata["facebook"]["content"],
                    "_blank"
                  )
                }
                to={``}
              >
                <FaceBook />
              </Socile>
              <Socile
                onClick={() =>
                  window.open(
                    getdata && getdata["telegram"]["content"],
                    "_blank"
                  )
                }
                to={``}
              >
                <Telegram />
              </Socile>
              <Socile
                onClick={() =>
                  window.open(getdata && getdata["line"]["content"], "_blank")
                }
                to={``}
              >
                <Line />
              </Socile>
            </BankW>
          </Col>
        </Row>
      </Container>
      <Container>
        <CompanyPartner>
          <ul>
            <li>
              <img src={SBC369} />
            </li>
            <li>
              <img src={Joker} />
            </li>
            <li>
              <img src={k99} />
            </li>
            <li>
              <img src={AE} />
            </li>
            <li>
              <img src={ag} />
            </li>
            <li>
              <img src={gameworld} />
            </li>
            <li>
              <img src={sv} />
            </li>
          </ul>
        </CompanyPartner>
      </Container>{" "}
      <Container fluid style={{ padding: 0, background: colors.primary100 }}>
        <Container>
          <Row>
            <Col md={8}>
              <InfoWrap>
                <dt>
                  <Link to="">
                    <img src={logo} />
                  </Link>
                </dt>
                <dd className="links-cat-a">
                  <Link to="">About Us</Link>
                </dd>
                <dd className="links-cat-a">
                  <Link to="">Info Centre</Link>
                </dd>
                <dd className="links-cat-a">
                  <Link to="">Contact Us</Link>
                </dd>
                <dd className="links-cat-a">
                  <Link to="">License</Link>
                </dd>
                <dd className="links-cat-a">
                  <Link to="">Terms &amp; Conditions</Link>
                </dd>
              </InfoWrap>
            </Col>
            <Col md={4}>
              <Bottomcopy
                style={{ marginBottom: isTabletOrMobileDevice ? 65 : 0 }}
              >
                &copy;{new Date().getFullYear()}. All rights reserved for those
                aged 18+
              </Bottomcopy>
            </Col>
          </Row>
        </Container>
      </Container>
    </React.Fragment>
  );
}
const CompanyPartner = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  ul {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 50px;
    gap: 20px;
    li {
      display: flex;
      justify-content: center;
      width: 100px;
      list-style-type: none;
      filter: grayscale(1);
      align-items: center;
      &:first-child {
        width: 120px;
      }
      h4 {
        font-size: 1.4rem;
        font-weight: 600;
        text-transform: capitalize;
        padding-right: 10px;
        color: ${colors.primary};
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &:hover {
        filter: none;
      }
    }
  }
`;
const Wphone = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  span {
    margin-left: 15px;
    position: relative;
    padding-left: 15px;
    &::before {
      content: "";
      position: absolute;
      width: 2px;
      background-color: ${colors.primary};
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  p {
    color: ${colors.primary};
    font-weight: 600;
    text-align: left;
    margin: auto 0;
    font-size: 18px;
    line-height: 24px;
  }
`;

const Socile = styled(Link)`
  background-color: ${colors.primary100};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  position: relative;
  transition: all ease-in-out 0.3s;
  &:hover {
    background-color: ${colors.primary200};
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const BankW = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  margin: 20px 0;
`;
const BankCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 60px;
  overflow: hidden;
  border-radius: 5px;
  img {
    width: 60px;
    height: 60px;
  }
`;

const Bottomcopy = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
`;

const InfoWrap = styled.dl`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  font-weight: 500;
  dt {
    width: 150px;
    height: 30px;
    a {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  dd {
    border-right: 1px solid #000;
    padding-right: 10px;
    text-decoration:underline;
    &:last-child{
      border-right:1px solid transparent ;
    }
    a {
      color: #000;
      &:hover {
        color: ${colors.primary};
      }
    }
  }
`;
