import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { colors } from "../theam";
import { openModal as openModalLogin } from "../features/loGin";
import { useDispatch } from "react-redux";

export default function CardGame({ data }) {
  const { id, name, img, category } = data;
  const dispatch = useDispatch();
  return (
    <div onClick={() => dispatch(openModalLogin())}>
      <Link to="#">
        <GameWrap className="card-game" src={img} />
        <TitleGame>{name}</TitleGame>
        <Category>{category}</Category>
      </Link>
    </div>
  );
}
const GameWrap = styled.div`
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${({ src }) => src});
  /* background-color: #f0f0f0; */
  border-radius: 5px;
  width: 100%;
  height: 170px;
  /* aspect-ratio: 16/8; */
  transition: all 0.2s linear;
  position: relative;
  overflow: hidden;
  @keyframes shine {
    0% {
      top: -150%;
      left: -150%;
    }
    20% {
      left: 100%;
      top: 100%;
    }
    40% {
      left: 100%;
      top: 100%;
    }
    100% {
      left: 100%;
      top: 100%;
    }
  }
  &:hover {
    background-size: 105%;
    &:before {
      content: "";
      z-index: 3;
      position: absolute;
      height: 100%;
      width: 100%;
      top: -120%;
      left: -120%;
      background: linear-gradient(
        transparent 0%,
        rgba(255, 255, 255, 0.1) 45%,
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0.1) 55%,
        transparent 100%
      );
      transition: all 2s;
      transform: rotate(-45deg);
      animation: shine 7s infinite forwards;
    }
  }
`;
const Category = styled.h4`
  color: ${colors.black};
  text-transform: capitalize;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  padding-left: 10px;
`;
const TitleGame = styled.h4`
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  color: ${colors.primary};
  text-align: left;
  text-transform: capitalize;
  padding-left: 10px;
`;
