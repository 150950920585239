import React from 'react'
import styled from "styled-components";
import { colors } from '../theam';
import { Link } from 'react-router-dom';
export default function MobileGameCard({ button, data }) {
    const { name, img, des, slug } = data
    return (
        <GameWrap
        >
            <Link to={`game/${slug}`}>
                <div className="card-game"
                    style={{ backgroundImage: `url(${img})` }}>
                </div>
                <h1>{name}</h1>
            </Link>
        </GameWrap>
    )
}
const GameWrap = styled.div`
      margin-bottom: 30px;
      .card-game{
          display: flex;
          aspect-ratio: 1/1;
          background-position: center;
            background-repeat: no-repeat;
            background-size:80%;
            position: relative;
            }
            &:before{
                content: '';
                position: absolute;
                width: 90%;
                transform: translate(-50%,-50%);
                top: 47%;
                left: 50%;
                aspect-ratio: 1/1;
                background-color: ${colors.primary200};
                border-radius: 20px;
                z-index: 0;
            }
      h1{
         position: absolute;
        color: ${colors.primary};
        line-height: 30px;
        font-size: 18px;
        left: 50%;
        bottom: -12%;
        transform: translate(-50%,-50%);
        margin: 0;
        text-transform: capitalize;
        display: block;
        z-index: 2;
      }
      a{
          display: block;
          flex-direction: row;
          justify-content: center;
      }
      p{
        margin-top: 15px;
        margin-bottom: 0;
        font-weight: 500;
        line-height: 23px;
        color: ${colors.primary};
      }
      .button{
        position: absolute;
        display: inline-block;
        width: 120px;
        padding: 15px;
        left: 30px;
        bottom: 30px;
      }
`;