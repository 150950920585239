import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { fomateDate } from "../utils";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Label } from "./../components/Component";
import { getImage, cutSting } from "./../utils";
import { Link } from "react-router-dom";
import { colors } from "./../theam";
import config from "./../config";

export default function Article() {
  let { id } = useParams();
  const [oldid, setId] = React.useState(id);
  const [article, setaAticle] = React.useState({});
  const [relate, setaRelate] = React.useState([]);
  const [isPending, setIsPending] = React.useState(true);
  if (oldid != id) {
    window.location.reload();
  }
  const getdatadetail = async () => {
    await axios.get(`/article/${id}`).then((resp) => {
      setIsPending(false);
      setaAticle(resp.data.acticle);
    });
  };
  const getrelateitems = () =>
    axios.get(`/home`).then((resp) => {
      setaRelate(resp.data.articles);
    });

  React.useEffect(() => {
    getdatadetail();
    getrelateitems();
  }, []);

  return (
    <Container>
      {isPending && (
        <div className="style style1">
          <div className="dot1"></div>
        </div>
      )}
      <Row style={{ padding: 10 }}>
        <Col md={8}>
          <WrapArticles>
            <h1>{article.title}</h1>
            <p className="date">{fomateDate(article.created_at)}</p>
            <p className="body">
              {/* <ReactMarkdown
                                rehypePlugins={[rehypeRaw]}
                                remarkPlugins={[gfm]}
                                transformImageUri={uri =>
                                    uri.startsWith("http") ? uri : `${config.mediaUrl}${uri}`
                                }
                            >{article.content}</ReactMarkdown> */}
              <div dangerouslySetInnerHTML={{ __html: article.content }} />
            </p>
          </WrapArticles>
        </Col>
        <Col md={4}>
          <Label>{article.category}</Label>
          <Wrap>
            {relate.map((item, idx) => {
              return (
                <Link key={item.id} to={`/article/${item.id}`} className="item">
                  <div
                    className="card-news"
                    style={{ backgroundImage: `url(${getImage(item.image)})` }}
                  ></div>

                  <span>
                    <h3>{cutSting(item.title, 30)}</h3>
                    <h3>{cutSting(item.id)}</h3>
                    <p>{cutSting(item.description, 40)}</p>
                  </span>
                </Link>
              );
            })}
          </Wrap>
        </Col>
      </Row>
    </Container>
  );
}
const Wrap = styled.div`
  padding-left: 20px;
  .item {
    min-width: 100px;
    height: 150px;
    color: ${colors.primary};
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    overflow: hidden;
    &:hover {
      .card-news {
        transform: scale(1.08, 1.08);
        opacity: 0.9;
      }
    }
    span {
      margin-left: 10px;
    }
  }
  .card-news {
    aspect-ratio: 1/1;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-size: cover;
    transition: all 0.5s;
  }
`;

const WrapArticles = styled.div`
  margin-top: 15px;
  h1 {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.6;
  }
  img {
    width: 100%;
  }
  .body {
    margin-top: 20px;
  }
  .date {
    margin-top: 10px;
  }
`;
