import React, { useState } from "react";
import { Input } from "./Component";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import {
  Typography,
  DialogActions,
  DialogContent,
  Dialog,
  IconButton,
  withStyles,
} from "@material-ui/core";
import $ from "jquery";
import red from "@material-ui/core/colors/red";
import signupImg from "./../images/signup.jpg";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../features/loGin";
import CloseIcon from "@material-ui/icons/Close";
import config from "../config";
import { getCookie, isUsername } from "../utils";
import { colors, fonts } from "../theam";
import { Button } from "../components/Component";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Snackbar } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default function Login({ t }) {
  const [action, setAction] = useState({
    loginerror: false,
    type: "",
    msg: "",
  });
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  const [type, setType] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handalLogin = (e) => {
    e.preventDefault();
    const loginData = {
      username: username,
      password: password,
      cert: config.cert,
    };
    if (username === "") {
      setAction({ loginerror: true, msg: "Your username must be empty!" });
      return;
    } else if (password === "") {
      setAction({ loginerror: true, msg: "Your Password must be empty!" });
      return;
    }
    fetch(`${config.baseURL}/api/cash/login/`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: config.token,
      },
      body: JSON.stringify(loginData),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "logged on successfully") {
          const en = getCookie("lang");
          window.location.href = `${res.domain}/?sid=${res.sessionid}&uid=${res.userid}&cert=${config.cert}&language=${en}`;
        } else {
          setAction({ loginerror: true, msg: res.message });
        }
      });
  };
  const { loginerror, msg } = action;
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={loginerror}
        autoHideDuration={2000}
        onClose={() => setAction({ loginerror: false })}
      >
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {msg} — <strong>check it out!</strong>
        </Alert>
      </Snackbar>
      <Dialog
        onClose={() => dispatch(closeModal())}
        maxWidth={`xs`}
        aria-labelledby="max-width-dialog-title"
        open={login.modal}
      >
        <Form method="post" autocomplete="off">
          <DialogTitle
            id="customized-dialog-title"
            onClose={() => dispatch(closeModal())}
          >
            {t("user.login")}
          </DialogTitle>
          <DialogContent dividers>
            <Row>
              <Col md={12} style={{ height: "300px" }}>
                <img
                  width="100%"
                  height="100%"
                  style={{ objectFit: "contain" }}
                  src={signupImg}
                  alt="signup"
                />
              </Col>
              <Col md={12} style={{ padding: 10 }}>
                <PwWrap style={{ paddingBottom: 10 }}>
                  <Input
                    className="username"
                    autocomplete="off"
                    text="text"
                    onChange={(e) => setUsername(e.target.value.toUpperCase())}
                    placeholder={t("user.username")}
                    value={username}
                  ></Input>
                </PwWrap>

                <PwWrap>
                  <Input
                    type={type ? "text" : "password"}
                    autocomplete="off"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder={t("user.password")}
                    value={password}
                  />
                  <IconButton
                    className="icon"
                    onClick={() => {
                      type ? setType(false) : setType(true);
                    }}
                  >
                    {type ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </PwWrap>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button
              type="submit"
              autoFocus
              onClick={(e) => handalLogin(e)}
              color="primary"
            >
              {t("user.login")}
            </Button>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
}

const PwWrap = styled.div`
  display: flex;
  input {
    width: 100%;
  }
  .icon {
    color: ${colors.primary};
    position: absolute;
    right: 0;
    padding: 10px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  span {
    display: flex;
    gap: 10px;
  }
  .err-msg,
  .phone-msg {
    color: ${red[400]};
    font-size: 12px;
    display: none;
    line-height: 12px;
  }
  .phone {
    padding-left: 80px;
    font-size: 18px;
    font-weight: 500;
    padding: 10px;
    border-radius: 5px;
    content: "+855";
    font-family: ${fonts};
    color: ${colors.primary};
    display: block;
    background: ${colors.primary100};
  }
  .error {
    background-color: ${red[100]};
    color: ${red[400]};
    ::placeholder {
      color: ${red[400]};
    }
  }
`;
