const colors = {
  primary: "#3C5AA0",
  primary100: "#D3E4F6",
  primary200: "#a7c8ea",
  primary300: "#a7c8ea85",
  secondary: "#eecd50",
  black: "#1a1d25",
  white: "#ffffff",
  gray: "#f7f7f7",
  gray2: "#a2a2a2",
  gradient: "linear-gradient(180deg, #feb238, #ffefb1, #feb238);",
};

const fonts = `'Montserrat','Battambang', sans-serif;`;
export { colors, fonts };
