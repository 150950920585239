import React from 'react'
import logo from './../images/logo.gif'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { colors } from './../theam'
import en from './../images/en.svg'
import km from './../images/km.svg'
import { setLanguage } from 'react-switch-lang';

export default function MobileHeader() {
    const handleSetLanguage = (key) => () => {
        setLanguage(key);
    };
    return (
        <MobileW>
            <Logo
                to='/'
                style={{
                    backgroundImage: `url(${logo})`,
                }} >
            </Logo>
            <div style={{ display: 'flex', marginRight: 15, padding: 0 }}>
                <IconButton onClick={handleSetLanguage('km')} >
                    <Lang style={{ backgroundImage: `url(${km})` }}></Lang>
                </IconButton>
                <IconButton onClick={handleSetLanguage('en')}>
                    <Lang  style={{ backgroundImage: `url(${en})` }}></Lang>
                </IconButton>
            </div>
        </MobileW>
    )
}

const Logo = styled(Link)`
    display:block;
    padding:0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -23px -26px;
    height: 50px;
    width: 50%;
`;
const Lang = styled.div`
    border:5px;
    color: ${colors.white};
    cursor: pointer;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;
const MobileW = styled.div`
    display: flex;
    justify-content: space-between;
    .hamberger{
        width: 35px;  
        height: 35px;
        right: 10px;
        overflow: hidden;
        cursor: pointer;
        
    }
    ul li{
    list-style: none;
    position: absolute;
    transform: translateY(-50%);
    width: 70%; 
    height: 3px;
    background: ${colors.primary};
    transition: 1s;
}
ul li:nth-of-type(1){
    top: 30%;  left: 15%;
    transition-delay: 1s;
    opacity: 1;
}
ul li:nth-of-type(2){
    top: 50%;  left: 15%;
    transition-delay: 0s;
}
ul li:nth-of-type(3){
    top: 70%;  right: 15%;
    transition-delay: 1s;
    opacity: 1;
}
ul.active li:nth-of-type(1){
    left: 100%;
    transition-delay: 0s;
    opacity: 0;
}
ul.active li:nth-of-type(2){
    transform: translateY(-50%) rotate(405deg);
    transition-delay: .5s;
}
ul.active li:nth-of-type(3){
    right: 100%;
    transition-delay: 0s;
    opacity: 0;
}




ul li:nth-of-type(2)::before{
   content: '';
   position: absolute;
   top: -150px;  left: 0;
   width: 100%;  height: 100%;
   background:${colors.primary};
   transform: rotate(90deg);
   transition: 1s;
   transition-delay: .5s;
}
ul.active li:nth-of-type(2)::before{
   top: 0;
   transition-delay: 1s;
}
`;