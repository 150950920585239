import styled from "styled-components";
import { colors, fonts } from "./../theam";

const boxshadow = "-1px 2px 46px -3px rgba(0,0,0,0.38)";

export const Label = styled.h2`
  color: ${colors.primary};
  position: relative;
  text-transform: capitalize;
  line-height: 30px;
  font-size: 1.7rem;
  text-align: center;
  margin: 30px 0;
  @media only screen and (max-width: 763px) {
    margin: 10px 0;
  }
`;

export const Button = styled.button`
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  display: block;
  cursor: pointer;
  color: ${colors.white};
  background: ${colors.primary};
  transition: 0.3s;
  line-height: 23px;
  text-align: center;
  font-family: ${fonts};
  border: none;
  &:hover {
    background-color: ${colors.secondary};
  }
`;

export const InputS = styled.input.attrs({ type: "submit" })`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 13px 20px;
  border: none;
  outline: none;
  display: block;
  cursor: pointer;
  color: ${colors.white};
  background: ${colors.primary};
  transition: 0.3s;
  line-height: 16px;
  text-align: center;
  &:hover {
    background-color: ${colors.secondary};
  }
`;

export const Input = styled.input`
  font-size: 18px;
  padding: 10px 15px;
  border: none;
  outline: none;
  border-radius: 3px;
  background: ${colors.primary100};
  color: ${colors.primary};
  position: relative;
  &:focus {
    background: #d9e3ee;
    box-shadow: inset 0 0 2px #3c5aa0;
  }
  ::placeholder {
    color: ${colors.primary};
    font-family: ${fonts};
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
  }
`;

export const H1 = styled.div`
  height: 50px;
  padding: 10px;
  overflow: hidden;
  font-size: 19px;
  background: ${colors.primary};
  color: ${colors.white};
`;

export const List = styled.li`
  border-radius: 15px;
  cursor: pointer;
  list-style: none;
  background: ${colors.white};
  box-shadow: ${boxshadow};
  margin: 10px 0;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  p {
    margin: 30px 10px;
    font-size: 18px;
  }
`;
