import { createSlice } from '@reduxjs/toolkit'

export const singUp = createSlice({
    name: 'counter',
    initialState: {
        modal:false,
        fid:null,
    },
    reducers: {
        openModal: (state) => {
            state.modal = true
        },
        closeModal:(state) =>{
            state.modal = false
        },
        setFid:(state,action) =>{
            state.fid = action.payload
        }
    },
})
export const { openModal,closeModal,setFid } = singUp.actions

export default singUp.reducer