const size = {
    xs: '320px',
    sm: '768px',
    lg: '1200px',
}
const device = {
    xs: `(max-width: ${size.xs})`,
    sm: `(max-width: ${size.sm})`,
    lg: `(max-width: ${size.lg})`
}
export { size, device }