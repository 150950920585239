import React, { useState } from 'react'
import { Input } from './Component'
import styled from 'styled-components'
import { Row, Col } from 'styled-bootstrap-grid'
import {
    Typography, DialogActions, DialogContent, Dialog, IconButton, withStyles
} from '@material-ui/core';
import $ from 'jquery'
import red from '@material-ui/core/colors/red';
import signupImg from './../images/signup.jpg'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { useSelector, useDispatch } from 'react-redux'
import { closeModal } from './../features/signUp'
import CloseIcon from '@material-ui/icons/Close'
import config from './../config'
import { getCookie, isUsername } from './../utils'
import { colors, fonts } from '../theam'
import { Button } from "./Component"
import {
    Visibility,
    VisibilityOff
} from '@material-ui/icons'
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default function Signup({ t }) {
    const signup = useSelector((state) => state.signup)
    const dispatch = useDispatch()
    const { fid } = signup
    const [data, setData] = useState({
        type: false
    })

    return (
        <div>
            <Dialog onClose={() => dispatch(closeModal())}
                maxWidth={`md`}
                aria-labelledby="max-width-dialog-title"
                open={signup.modal}>
                <DialogTitle id="customized-dialog-title" onClose={() => dispatch(closeModal())}>
                    {t('user.signup')}
                </DialogTitle>
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                        phone: '',
                        affiliate: fid ? fid : '',
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string()
                            .max(14, 'Username must be 6 to 14 characters')
                            .min(6, 'Username must be 6 to 14 characters')
                            .required('Username is required'),
                        phone: Yup.number('Phone number mus be').positive('This is not allow to nagative number').required('Phone number is required'),
                        password: Yup.string().required('Password is required'),
                        repassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
                    })}
                    onSubmit={async (value, { setErrors }) => {
                        await fetch(`${config.baseURL}/api/cash/check_name/`,
                            {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': config.token
                                },
                                body: JSON.stringify({
                                    account: value.username.toUpperCase().trim(),
                                    cert: config.cert
                                })
                            })
                            .then(res => res.json())
                            .then(res => {
                                if (!res.status) {
                                    setErrors({ username: res.message })
                                    return
                                }
                                if (res.status) {
                                    fetch(`${config.baseURL}/api/cash/registration/`,
                                        {
                                            method: 'post',
                                            headers: {
                                                'Content-Type': 'application/json',
                                                'Authorization': config.token
                                            },
                                            body: JSON.stringify({
                                                account: value.username.toLocaleUpperCase().trim(),
                                                cert: config.cert,
                                                name: value.username.trim(),
                                                password: value.password.trim(),
                                                contact: value.phone,
                                                affiliate: value.affiliate
                                            })
                                        })
                                        .then(res => res.json())
                                        .then(res => {
                                            if (res.message === "Affiliate is not valid, pls check again!") {
                                                setErrors({ affiliate: res.message })
                                            } else if (res.message === "The phone number is already exists!") {
                                                setErrors({ phone: res.message })
                                            }
                                            if (res.account) {
                                                fetch(`${config.baseURL}/api/cash/login/`,
                                                    {
                                                        method: 'post',
                                                        headers: {
                                                            'Content-Type': 'application/json',
                                                            'Authorization': config.token
                                                        },
                                                        body: JSON.stringify({
                                                            username: res.account,
                                                            password: res.password,
                                                            cert: config.cert,
                                                        })
                                                    })
                                                    .then(res => res.json())
                                                    .then(res => {
                                                        const en = getCookie('lang')
                                                        window.location.href = `${res.domain}/?sid=${res.sessionid}&uid=${res.userid}&cert=${config.cert}&language=${en}`
                                                    })
                                            }
                                        })
                                }
                            })
                    }}
                >
                    {({ handleChange, handleSubmit, isSubmitting, touched, values }) => (
                        <>
                            <Form onSubmit={handleSubmit}>
                                <DialogContent dividers>
                                    <Row>
                                        <Col md={5} style={{ paddingRight: 5 }}>
                                            <img width="100%" src={signupImg} alt="signup" />
                                        </Col>
                                        <Col md={7} style={{ paddingLeft: 5 }}>
                                            <div className="frm">
                                                <Input
                                                    className="username"
                                                    name="username"
                                                    autocomplete="false"
                                                    text="text"
                                                    onChange={handleChange}
                                                    style={{
                                                        textTransform: "uppercase"
                                                    }}
                                                    value={values.username}
                                                    placeholder={t('user.username')}
                                                />
                                                <ErrorMessage name="username" />
                                                <PwWrap>
                                                    <Input
                                                        type={data.type ? 'text' : 'password'}
                                                        autocomplete="false"
                                                        name="password"
                                                        onChange={handleChange}
                                                        placeholder={t('user.password')}

                                                    />
                                                    <IconButton className="icon" onClick={() => { data.type ? setData({ ...data, type: false }) : setData({ ...data, type: true }) }}>
                                                        {data.type ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </PwWrap>
                                                <ErrorMessage name="password" />
                                                <PwWrap>
                                                    <Input
                                                        type={data.type ? 'text' : 'password'}
                                                        autocomplete="false"
                                                        name="repassword"
                                                        onChange={handleChange}
                                                        placeholder={t('user.repassword')}
                                                    />
                                                    <IconButton className="icon" onClick={() => { data.type ? setData({ ...data, type: false }) : setData({ ...data, type: true }) }}>
                                                        {data.type ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </PwWrap>
                                                <ErrorMessage name="repassword" />
                                                <span>
                                                    <span className="phone">+855</span>
                                                    <Input
                                                        className="phone"
                                                        autocomplete="false"
                                                        style={{ width: '100%' }}
                                                        name="phone"
                                                        type="number"
                                                        onChange={handleChange}
                                                        placeholder={t('user.phone')}
                                                    ></Input>
                                                </span>
                                                <ErrorMessage name="phone" />
                                                <Input
                                                    disabled={fid ? true : false}
                                                    text="text"
                                                    style={{
                                                        background: fid ? colors.primary200 : colors.primary100
                                                    }}
                                                    autocomplete="false"
                                                    id="affiliate"
                                                    name="affiliate"
                                                    onChange={handleChange}
                                                    placeholder={t('user.affiliate')}
                                                />
                                                <ErrorMessage name="affiliate" />
                                            </div>
                                        </Col>
                                    </Row>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus
                                        type="submit" color="primary" disabled={isSubmitting}>
                                        {t('user.signup')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        </>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
}


const PwWrap = styled.div`
    display: flex;
    input{
        width: 100%;
    }
    .icon{
        color: ${colors.primary};
        position: absolute;
        right: 0;
        padding: 10px;
    }

`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    
    position: relative;
    span{
        display: flex;
        gap:10px;

    }
    .frm{
        display: flex;
        flex-direction: column;
        gap:10px;
    }
    
    .err-msg,.phone-msg{
        color: ${red[400]};
        font-size: 12px;
        display: none;
        line-height: 12px;
    }
    .phone{
        padding-left: 80px;
        font-size: 18px;
        font-weight: 500;
            padding: 10px;
            border-radius: 5px;
            content: '+855';
            font-family: ${fonts};
            color: ${colors.primary};
            display: block;
            background: ${colors.primary100};
    }
    .error{
        background-color: ${red[100]};
        color: ${red[400]};
            ::placeholder {
            color: ${red[400]};
        }
    }
`