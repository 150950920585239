import { createSlice } from '@reduxjs/toolkit'

export const loGin = createSlice({
    name: 'login',
    initialState: {
        modal:false,
    },
    reducers: {
        openModal: (state) => {
            state.modal = true
        },
        closeModal:(state) =>{
            state.modal = false
        },
    },
})
export const { openModal,closeModal } = loGin.actions

export default loGin.reducer