import React, { useEffect } from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import { useParams } from "react-router-dom";
import Slide from "./../components/Slide";
import { Label, Button } from "./../components/Component";
import CardGame from "../components/CardGame";
import NewsCard from "../components/NewsCard";
import { colors } from "../theam";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { setFid } from "./../features/signUp";

import Belangkal from "./../images/game/Belangkal.png";
import NeptuneTreasureBingo from "./../images/game/NeptuneTreasureBingo.png";
import caishenrichesbingo from "./../images/game/caishenrichesbingo.png";
import chilliHunTerBingo from "./../images/game/chilliHunTerBingo.png";
import jokermadness from "./../images/game/jokermadness.png";
import ball from "./../images/game/ball.png";
import DragonTiger from "./../images/game/DragonTiger.png";
import fishhaiba from "./../images/game/fishhaiba.png";
import fishhunter2exNovice from "./../images/game/fishhunter2ex-novice.png";
import ongbak2 from "./../images/game/ongbak2.png";
import streetsofchicago from "./../images/game/streetsofchicago.png";
import threekingdom2 from "./../images/game/threekingdom2.png";

import { Helmet } from "react-helmet";
import { getImage } from "../utils";
import { openModal } from "./../features/signUp";
import { openModal as openModalLogin } from "./../features/loGin";
import { useMediaQuery } from "react-responsive";
import MobileGameCard from "./../components/MobileGameCard";
import ReactPlayer from "react-player/lazy";
import video from "./../assets/video.mp4";
import SlideVertical from "../components/SlideVertical";
import { domain } from "../config";

const Home = ({ t, getdata }) => {
  let { fid } = useParams();
  const dispatch = useDispatch();

  const isTabletOrMobileDevice = useMediaQuery({ maxWidth: 763 });
  const [isPending, setIsPending] = React.useState(true);
  const { articles, banners, footer, match } = getdata;

  // console.log(footer ? footer["video"]["content"] : "");

  useEffect(() => {
    dispatch(setFid(fid));
    if (getdata) {
      setIsPending(false);
    }
  }, []);
  const game = [
    {
      id: 1,
      slug: "belangkal",
      name: "belangkal",
      img: Belangkal,
      category: "slot",
    },
    {
      id: 2,
      slug: "neptunetreasurebingo",
      name: "Neptune Treasure",
      img: NeptuneTreasureBingo,
      category: "bingo",
    },
    {
      id: 3,
      slug: "caishenrichesbingo",
      name: "caishen Riches",
      img: caishenrichesbingo,
      category: "bingo",
    },
    {
      id: 4,
      slug: "chilliHunterBingo",
      name: "chilli Hunter",
      img: chilliHunTerBingo,
      category: "bingo",
    },
    {
      id: 5,
      slug: "jokermadness",
      name: "joker Madness",
      img: jokermadness,
      category: "slot",
    },
    {
      id: 6,
      slug: "fishhaiba",
      name: "fish Haiba",
      img: fishhaiba,
      category: "fishing",
    },
    {
      id: 7,
      slug: "ball",
      name: "ball",
      img: ball,
      category: "fishing",
    },
    {
      id: 8,
      slug: "dragontiger",
      name: "dragonTiger",
      img: DragonTiger,
      category: "ECasino",
    },
    {
      id: 9,
      slug: "fishhunter2exNovice",
      name: "fishhunter2exNovice",
      img: fishhunter2exNovice,
      category: "fishing",
    },
    {
      id: 10,
      slug: "ongbak2",
      name: "ong Bak 2",
      img: ongbak2,
      category: "slot",
    },
    {
      id: 11,
      slug: "streetsofchicago",
      name: "streets Of Chicago",
      img: streetsofchicago,
      category: "slot",
    },
    {
      id: 12,
      slug: "threekingdom2",
      name: "three Kingdom 2",
      img: threekingdom2,
      category: "slot",
    },
  ];
  if (isPending) {
    return (
      <div className="style style1">
        <div className="dot1"></div>
      </div>
    );
  }
  return (
    <Container fluid style={{ color: colors.white }}>
      <Helmet>
        <meta name="keywords" content="your, tags" />
        <meta name="copyright" content="DB 188" />
        <meta name="language" content="en" />
        <meta name="category" content="sport betting" />
      </Helmet>
      <Slide data={banners} />
      {isTabletOrMobileDevice ? (
        <BtnW>
          <Button type="reset" onClick={() => dispatch(openModal())}>
            {t("user.signup")}
          </Button>
          <Button type="reset" onClick={() => dispatch(openModalLogin())}>
            {t("user.login")}
          </Button>
        </BtnW>
      ) : (
        ""
      )}
      <Container>
        <Label>{t("label.sport")}</Label>
        {isTabletOrMobileDevice ? (
          <>
            {/* Mobile */}
            <MobileGameW>
              <Row>
                {game.map((index, key) => {
                  return (
                    <Col xs={6} key={key}>
                      <MobileGameCard data={index} />
                    </Col>
                  );
                })}
              </Row>
            </MobileGameW>
          </>
        ) : (
          <>
            {/* Desktop */}
            <Row>
              <Col md={7} style={{ paddingRight: "10px" }}>
                <BoxHeadTitle>
                  <Boxhalf flexposi="flex-start">
                    <LabelTop>{t("label.promotion")}</LabelTop>
                  </Boxhalf>
                  <Boxhalf flexposi="flex-end">
                    <Button onClick={() => dispatch(openModalLogin())}>
                      {t("label.betnow")}
                    </Button>
                  </Boxhalf>
                </BoxHeadTitle>
                <ReactPlayer
                  loop
                  muted
                  controls
                  playing
                  url={footer ? footer["video"]["content"] : ""}
                  width={`100%`}
                  height="390px"
                  className="video-player"
                />
              </Col>
              <Col md={5} style={{ paddingLeft: "10px" }}>
                <MainMatch>
                  <BoxHeadTitle>
                    <div style={{ width: "50%" }}>
                      <LabelTop>{t("label.upcoming")}</LabelTop>
                    </div>
                    <Boxhalf flexposi="flex-end">
                      <Button onClick={() => dispatch(openModalLogin())}>
                        {t("label.betnow")}
                      </Button>
                    </Boxhalf>
                  </BoxHeadTitle>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <SlideVertical data={match} />
                  </div>
                </MainMatch>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <BoxHeadTitle style={{ marginTop: "20px" }}>
                  <Boxhalf flexposi="flex-start">
                    <LabelTop>{t("label.hotgame")}</LabelTop>
                  </Boxhalf>
                  <Boxhalf flexposi="flex-end">
                    <Button onClick={() => dispatch(openModalLogin())}>
                      {t("label.viewall")}
                    </Button>
                  </Boxhalf>
                </BoxHeadTitle>
              </Col>
            </Row>
            <Row>
              {game.map((index, key) => {
                if (key >= 6) {
                  return;
                }
                return (
                  <Col key={key} md={2} style={{ paddingLeft: "5px" }}>
                    <CardGame data={index} />
                  </Col>
                );
              })}
            </Row>
            <Row style={{ marginTop: "20px" }}>
              {game.map((index, key) => {
                if (key < 6) {
                  return;
                }
                return (
                  <Col key={key} md={2} style={{ paddingLeft: "5px" }}>
                    <CardGame data={index} />
                  </Col>
                );
              })}
            </Row>
          </>
        )}
        {window.location.hostname === domain ? (
          ""
        ) : (
          <>
            <Label>{t("label.news")}</Label>
            <Row>
              <NewsCard data={articles} />
            </Row>
          </>
        )}
      </Container>
    </Container>
  );
};
export default Home;

const MobileGameW = styled.div`
  padding: 0 10px;
`;

const WgameTop = styled.div`
  margin-left: 10px;
  margin-top: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const BtnW = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  button {
    width: 100%;
  }
`;

const MainMatch = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Boxhalf = styled.div`
  width: 50%;
  display: flex;
  justify-content: ${(props) => props.flexposi};
`;

const LabelTop = styled.h2`
  height: 100%;
  color: ${colors.primary};
  position: relative;
  text-transform: capitalize;
  line-height: 39px;
  font-size: 1.5rem;
  text-align: left;
  @media only screen and (max-width: 763px) {
    margin: 10px 0;
  }
`;

const BoxHeadTitle = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 10px;
`;
