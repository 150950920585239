import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { GridThemeProvider } from 'styled-bootstrap-grid';
import "./assets/css/owl.carousel.css"
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { colors } from './theam';
import store from './app/store'
import { Provider } from 'react-redux'
import axios from 'axios'
import config from './config'

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post['Content-Type'] = 'application/json';


export default function Index() {

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: colors.primary
      },
      secondary: {
        main: colors.secondary
      }
    },
    typography: {
      fontFamily: ['Montserrat', 'Battambang'],
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600,
      fontWeightBold: 700,
    }
  })

  const gridTheme = {
    container: {
      padding: 0,
      maxWidth: {
        xxl: 1200,
        xl: 1200,
        lg: 960,
        md: 720,
        sm: 540,
        xs: 540,
      },
    },
    row: {
      padding: 0,
    },
    col: {
      padding: 0,
    },
  };
  return (
    <ThemeProvider theme={theme}>
      <GridThemeProvider gridTheme={gridTheme}>
        <Provider store={store}>
          <App />
        </Provider>
      </GridThemeProvider>
    </ThemeProvider>
  )
}



ReactDOM.render(
  <Index/>,
  document.getElementById('root')
);
