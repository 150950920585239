import { createSlice } from '@reduxjs/toolkit'

export const storeReducer = createSlice({
    name: 'counter',
    initialState: {
        slide: [],
    },
    reducers: {
        getSlide: (state,{payload}) => {
            state.slide = payload
        },

    },
})
export const { getSlide } = storeReducer.actions

export default storeReducer.reducer