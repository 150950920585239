import config from './../config'
import moment from 'moment'

function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
}
function isUsername(s) {
    const re = /^[A-Z0-9.-]+$/;
    return re.apicrud(String(s));
}

function getImage(img){
    if(img!==undefined){
        return config.mediaUrl+img
    }else{
        return 'https://i.stack.imgur.com/y9DpT.jpg'
    }
}

function cutSting(string, length=100) {
        return string.length > length ?
            string.substring(0, length) + '...' :
            string;
}


function fomateDate(date){
    const lang = getCookie('lang')
    moment.locale(lang)
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
}




export { getCookie, isUsername, getImage, cutSting, fomateDate, }