import { configureStore } from '@reduxjs/toolkit'
import { signUp, lang,loGin, storeReducer,popUp } from '../features'

export default configureStore(
    {
        reducer: {
            signup: signUp,
            login: loGin,
            lang: lang,
            popup:popUp,
            store: storeReducer
        },

    }, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)