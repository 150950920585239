import React from 'react'
import styled from 'styled-components'
import { colors } from './../theam'
import { Home,Promotion,News } from './icons'
import { NavLink } from 'react-router-dom'
import { IconButton } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'
export default function BottomNav() {
    const isTabletOrMobileDevice = useMediaQuery({ maxWidth: 763 })
  return isTabletOrMobileDevice ? <Bottom>
        <NavLink to='/' exact activeClassName='active'>
            <Home color={colors.white} />
            <span>Home</span>
        </NavLink>
      <NavLink to='/promotion' activeClassName='active'>
          <Promotion color={colors.white} />
            <span>promotion</span>
        </NavLink>
        <NavLink to='/sportnews' activeClassName='active'>
          <News color={colors.white} />
            <span>News</span>
        </NavLink>
    </Bottom> :''
}



const Bottom = styled.div`
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    background-color: ${colors.primary};
    flex-direction: row;
    justify-content: space-around;
    color: ${colors.white};
    .active{
        background-color: ${colors.secondary};
    }
    span{
        font-size: 12px;
        margin-top: 5px;
    }
    a{
        padding: 10px;
        width: 100%;
        display: flex;
        flex-direction:column;
        justify-content: center;
        align-items: center;
    }
`;