import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col } from "styled-bootstrap-grid";
import axios from "axios";
import styled from "styled-components";
import { getImage, cutSting } from "./../utils";
import DateCountdown from "react-date-countdown-timer";
import { colors } from "./../theam";
import $ from "jquery";
import { Button } from "../components/Component";
import { useDispatch } from "react-redux";
import {
  closeModal,
  openModal1,
  setModalData,
} from "./../features/popupDetail";
//pop up detail promotion
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import { useSelector } from "react-redux";
import { openModal as openModalLogin } from "./../features/loGin";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function Promotion() {
  const isCancelled = useRef(false);
  const [promotion, setPromotion] = useState("");
  const [type, setType] = useState("0");
  const dispatch = useDispatch();
  const state = useSelector((state) => state.popup);
  const { modal1, data } = state;

  useEffect(() => {
    getalldata(isCancelled);
    return () => {
      isCancelled.current = true;
    };
  }, []);
  const getalldata = (isCancelled) => {
    axios
      .get("/promotion")
      .then((response) => {
        if (!isCancelled.current) {
          setPromotion(response.data);
        }
      })
      .catch((error) => console.error(`Error:${error}`));
  };
  if (promotion.length === 0) {
    return "";
  }
  const expired = (id) => {
    $(id).html('<span class="odometer-block">expired</span>');
  };

  const setopen = (index) => {
    dispatch(openModal1());
    dispatch(setModalData(index));
  };
  const apply = () => {
    // dispatch(openModal());
    dispatch(closeModal());
    dispatch(openModalLogin());
  };
  return (
    <Container style={{ padding: 10 }}>
      <div style={{ padding: "10px", display: "flex", gap: 10 }}>
        {promotion &&
          promotion.promotion.map((i, key) => {
            return (
              <Promotypeboxtop
                onClick={() => {
                  setType(key);
                }}
                key={key}
              >
                <TextUnderTitle>{i.type_promotion}</TextUnderTitle>
              </Promotypeboxtop>
            );
          })}
      </div>
      {promotion.promotion[type].data.map((index, key) => {
        let id = key;
        if (index.show_top) {
          return (
            <Wrappro key={key}>
              <Row>
                <Col md={6}>
                  <div
                    className="bg"
                    style={{ backgroundImage: `url(${getImage(index.image)})` }}
                  ></div>
                </Col>
                <Col md={6}>
                  <aside>
                    <h1
                      style={{ cursor: "pointer" }}
                      onClick={() => setopen(index)}
                    >
                      {index.title}
                    </h1>
                    <p>{cutSting(index.content)}</p>
                    <span className="datetime" id={`datetime-${id}`}>
                      <DateCountdown
                        dateTo={index.Expiry_date}
                        callback={() => expired(`#datetime-${id}`)}
                      />
                    </span>
                  </aside>
                </Col>
              </Row>
            </Wrappro>
          );
        } else {
          return "";
        }
      })}

      <Row>
        {promotion.promotion[type].data.map((index, key) => {
          if (!index.show_top) {
            return (
              <Col md={4} key={key}>
                <PromotionItem onClick={() => setopen(index)}>
                  <div
                    style={{
                      backgroundImage: `url(${getImage(index.image)})`,
                      borderRadius: "10px",
                      marginBottom: "5px",
                    }}
                  ></div>
                  {index.title}
                </PromotionItem>
              </Col>
            );
          } else {
            return "";
          }
        })}
      </Row>
      <Dialog
        onClose={() => dispatch(closeModal())}
        aria-labelledby="customized-dialog-title"
        open={modal1}
        maxWidth="md"
        TransitionComponent={Transition}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => dispatch(closeModal())}
        >
          {data.title}
        </DialogTitle>
        <DialogContent dividers>
          <Row>
            <Col md={12} style={{ paddingRight: 5 }}>
              <img width="100%" src={getImage(data.image)} alt="promotion" />
              <Typography gutterBottom>{data.content}</Typography>
            </Col>
          </Row>
        </DialogContent>
        <DialogTitle>
          <Button style={{ float: "right" }} onClick={() => apply()}>
            APPLY
          </Button>
        </DialogTitle>
      </Dialog>
    </Container>
  );
}
const Promotypeboxtop = styled.div`
  text-align: center;
`;
const TextUnderTitle = styled.div`
  white-space: nowrap;
  padding: 10px 15px;
  background-color: rgb(211, 228, 246);
  color: ${colors.primary};
  text-align: center;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 600;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  @keyframes shine {
    0% {
      top: -120%;
      left: -120%;
    }
    20% {
      left: 100%;
      top: 100%;
    }
    40% {
      left: 100%;
      top: 100%;
    }
    100% {
      left: 100%;
      top: 100%;
    }
  }
  &:before {
    content: "";
    z-index: 3;
    position: absolute;
    height: 100%;
    width: 100%;
    top: -120%;
    left: -120%;
    background: linear-gradient(
      transparent 0%,
      rgba(255, 255, 255, 0.1) 45%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.1) 55%,
      transparent 100%
    );
    transition: all 2s;
    transform: rotate(-45deg);
    animation: shine 7s infinite forwards;
  }
  &:hover {
    background: #a7c8ea;
  }
`;
const PromotionItem = styled.div`
  cursor: pointer;
  margin-top: 15px;
  padding: 0 10px;
  div {
    width: 100%;
    aspect-ratio: 18/9;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &:hover {
    text-decoration: underline;
  }
`;

const Wrappro = styled.div`
  padding: 10px;
  .odometer-block {
    color: ${colors.white};
    font-weight: 700;
    font-size: 24px;
  }
  .bg {
    aspect-ratio: 18/9;
    width: 100% !important;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
  }
  h1 {
    color: ${colors.primary};
    &:hover {
      text-decoration: underline;
    }
  }
  p {
    font-size: 16px;
    color: ${colors.black};
    font-weight: 400;
  }
  aside {
    padding-left: 20px;
    gap: 15px;
    display: flex;
    flex-direction: column;
  }
  .datetime {
    cursor: pointer;
    /* background-color: ${colors.secondary}; */
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    border: 4px solid ${colors.primary};
    span {
      color: ${colors.primary};
    }
    position: relative;
    &::before {
      opacity: 0;
      content: "APPLY";
      position: absolute;
      font-size: 20px;
      font-weight: 800;
      background-color: ${colors.secondary};
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: 5px;
      z-index: 5;
      justify-content: center;
      align-items: center;
      display: flex;
      line-height: 20px;
      color: ${colors.white};
      transition: all ease-in-out 0.5s;
    }
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
`;
